import { ColorSchema } from 'vendor/material-minimal/palette';
import {
  HTMLProps,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { alpha } from '@mui/system/colorManipulator';
import { getDescriptionColor } from 'app/shared/u21-ui/components/input/select/utils';
import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { IconCheck, IconChevronRight } from '@u21/tabler-icons';
import { Fade, Menu, MenuItem, PopoverOrigin } from '@mui/material';
import { U21Checkbox } from 'app/shared/u21-ui/components/input/U21Checkbox';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import {
  U21Tooltip,
  U21TooltipProps,
} from 'app/shared/u21-ui/components/display/U21Tooltip';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

export interface SelectOptionItemProps
  extends Omit<HTMLProps<HTMLLIElement>, 'children'> {
  alignRight?: boolean;
  autoOpen?: boolean;
  checkbox?: boolean;
  checkmark?: boolean;
  children?: SelectOptionItemProps[];
  color?: ColorSchema;
  description?: ReactNode;
  disabled?: boolean;
  icon?: ReactElement;
  indeterminate?: boolean;
  key?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  onClose?: () => void;
  selected?: boolean;
  text: string | number;
  tooltip?: U21TooltipProps['tooltip'];
  tooltipProps?: Omit<U21TooltipProps, 'children' | 'tooltip'>;
}

const getPopoverXCoordinate = (
  anchorElement: HTMLLIElement | null,
  alignRight: boolean,
): PopoverOrigin['horizontal'] => {
  if (alignRight) {
    return -8;
  }
  if (!anchorElement) {
    return 'right';
  }
  return anchorElement.getBoundingClientRect().width + 8;
};

export const SelectOptionItem = ({
  alignRight = false,
  autoOpen,
  checkbox,
  checkmark,
  children = [],
  color,
  description,
  disabled,
  icon,
  indeterminate,
  onClick,
  selected,
  text,
  tooltip,
  tooltipProps,
  ...rest
}: SelectOptionItemProps) => {
  const ref = useRef<HTMLLIElement>(null);
  const [open, setOpen] = useState(false);

  const hasSubMenu = children.length > 0;

  const onOpenMenu = () => setOpen(true);
  const onCloseMenu = () => setOpen(false);

  useEffect(() => {
    if (autoOpen && selected) {
      // open after render completes so the menu is positioned correctly
      setTimeout(() => setOpen(true), 0);
    }
  }, [autoOpen, selected]);

  return (
    <>
      <U21Tooltip tooltip={tooltip} placement="right">
        <StyledMenuItem
          $disabled={disabled}
          $selected={selected}
          disableRipple={disabled}
          onClick={(e) => {
            e.stopPropagation();
            if (disabled) return;
            onClick?.(e);
            if (hasSubMenu) {
              setOpen(!open);
            } else {
              onCloseMenu();
            }
          }}
          {...getDOMProps(rest)}
          aria-selected={selected}
          onMouseEnter={hasSubMenu ? onOpenMenu : undefined}
          onMouseLeave={hasSubMenu ? onCloseMenu : undefined}
          ref={ref}
          role="option"
        >
          <MenuItemContent>
            <StyledU21Spacer horizontal>
              {checkbox && (
                <StyledU21Checkbox
                  disabled={disabled}
                  checked={indeterminate ? false : selected}
                  indeterminate={indeterminate}
                  onChange={() => {}}
                />
              )}
              {icon && <IconContainer>{icon}</IconContainer>}
              <OptionTextContainer>
                <U21Typography variant="body2">{text}</U21Typography>
                <U21Typography
                  color={getDescriptionColor(color)}
                  variant="body2"
                >
                  {description}
                </U21Typography>
              </OptionTextContainer>
              {checkmark && selected && (
                <OptionRightIcon>
                  <StyledIconCheck />
                </OptionRightIcon>
              )}
              {hasSubMenu && <StyledIconChevronRight $color={color} />}
            </StyledU21Spacer>
          </MenuItemContent>
        </StyledMenuItem>
      </U21Tooltip>
      {!disabled && hasSubMenu && (
        <StyledMenu
          anchorEl={ref.current}
          anchorOrigin={{
            vertical: -8,
            horizontal: getPopoverXCoordinate(ref.current, alignRight),
          }}
          autoFocus={false}
          hideBackdrop
          disableAutoFocus
          disableEnforceFocus
          MenuListProps={{
            onMouseEnter: onOpenMenu,
          }}
          open={open}
          // use Fade instead of default Grow transition due to positioning
          // issues when opening the menu when selected
          TransitionComponent={Fade}
          transformOrigin={{
            vertical: 'top',
            horizontal: alignRight ? 'right' : 'left',
          }}
          variant="menu"
        >
          {children.map((i) => {
            return (
              <SelectOptionItem
                disabled={i.disabled}
                key={String(i.text)}
                alignRight={alignRight}
                onClose={onCloseMenu}
                {...i}
              />
            );
          })}
        </StyledMenu>
      )}
    </>
  );
};

const StyledMenuItem = styled(MenuItem)<{
  $disabled?: boolean;
  $selected?: boolean;
}>`
  padding: 0;
  border-radius: 8px;

  ${(props) =>
    props.$selected
      ? css`
          background-color: ${alpha(
            props.theme.palette.primary.main,
            props.theme.palette.mode === 'light' ? 0.08 : 0.16,
          )};
          :hover {
            background-color: ${alpha(
              props.theme.palette.primary.main,
              props.theme.palette.mode === 'light' ? 0.16 : 0.24,
            )};
          }
        `
      : css``}

  ${(props) =>
    props.$disabled
      ? css`
          opacity: 0.48;
          cursor: not-allowed;
          :hover {
            background: transparent;
          }
        `
      : css``}
`;

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  min-height: 46px;
  white-space: pre-wrap;
`;

const StyledU21Spacer = styled(U21Spacer)`
  width: 100%;
`;

// use negative margin to get the checkbox hover expand past the parent
export const StyledU21Checkbox = styled(U21Checkbox)`
  &&& {
    margin: -9px 0 -9px -4px;
  }
`;

const IconContainer = styled.div`
  display: flex;
`;

const OptionTextContainer = styled.div`
  flex: 1 1 auto;
`;

const OptionRightIcon = styled.div`
  display: flex;
  margin-left: 8px;
`;

const StyledIconCheck = styled(IconCheck)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const StyledIconChevronRight = styled(IconChevronRight)<{ $color?: string }>`
  margin-left: 8px;
  ${(props) => {
    const { $color, theme } = props;
    return (
      $color &&
      css`
        color: ${theme.palette[$color].main};
      `
    );
  }}
`;

const StyledMenu = styled(Menu)`
  pointer-events: none;

  .MuiList-root {
    padding: 8px;
    pointer-events: auto;
  }
`;
