import { useEffect } from 'react';

import { Prompt, useLocation } from 'react-router-dom';

export interface U21LeaveWarningProps {
  ignoredRoutes?: (route: string) => boolean;
  when: boolean;
}

const MESSAGE = 'Are you sure you want to leave without saving?';

export const U21LeaveWarning = ({
  ignoredRoutes = () => false,
  when,
}: U21LeaveWarningProps) => {
  const { pathname } = useLocation();
  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const onBeforeUnload = (e) => {
      if (when) {
        // eslint-disable-next-line no-param-reassign
        e.returnValue = MESSAGE;
        return MESSAGE;
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [when]);

  return (
    <Prompt
      message={({ pathname: nextPathname }) => {
        if (nextPathname === pathname || ignoredRoutes(nextPathname)) {
          return true;
        }
        return MESSAGE;
      }}
      when={when}
    />
  );
};
