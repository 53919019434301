import {
  FC,
  HTMLProps,
  MouseEventHandler,
  ReactElement,
  ReactNode,
} from 'react';
import { useDispatch } from 'react-redux';

// Components
import { IconX } from '@u21/tabler-icons';
import {
  U21Button,
  U21ButtonProps,
  U21Divider,
  U21Loading,
  U21Typography,
} from 'app/shared/u21-ui/components';

// Actions
import { hideSidebar } from 'app/modules/sidebar/slice';

// Style
import styled, { css } from 'styled-components';

export interface U21SideMenuProps
  extends Omit<HTMLProps<HTMLDivElement>, 'title'> {
  actionButtonProps?: U21ButtonProps;
  children: ReactNode;
  onAction?: MouseEventHandler<HTMLButtonElement>;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  footerLeftContent?: ReactNode;
  noPadding?: boolean;
  title: string | ReactNode;
  titleIcon?: ReactElement;
  loading?: boolean;
}

export const U21SideMenu: FC<U21SideMenuProps> = ({
  actionButtonProps,
  children,
  onAction,
  onClose,
  footerLeftContent,
  noPadding,
  title,
  titleIcon,
  loading = false,
}) => {
  const dispatch = useDispatch();
  return (
    <Container>
      <Header>
        {typeof title === 'string' ? (
          <HeaderTypography icon={titleIcon} variant="h4">
            {title}
          </HeaderTypography>
        ) : (
          title
        )}
        <U21Button
          aria-label="close"
          onClick={(e) => {
            onClose?.(e);
            dispatch(hideSidebar());
          }}
        >
          <IconX />
        </U21Button>
      </Header>
      {loading ? (
        <LoadingContainer>
          <U21Loading loading />
        </LoadingContainer>
      ) : (
        <Content $noPadding={noPadding}>{children}</Content>
      )}
      {(Boolean(onAction) ||
        Boolean(actionButtonProps) ||
        Boolean(footerLeftContent)) && (
        <>
          <U21Divider horizontal />
          <Footer>
            {footerLeftContent ?? <div />}
            {(Boolean(onAction) || Boolean(actionButtonProps)) && (
              <U21Button
                // eslint-disable-next-line react/no-children-prop
                children="Action"
                color="primary"
                variant="contained"
                {...actionButtonProps}
                onClick={(e) => {
                  onAction?.(e);
                  if (actionButtonProps?.to) {
                    dispatch(hideSidebar());
                  }
                }}
              />
            )}
          </Footer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 8px;
`;

const HeaderTypography = styled(U21Typography)`
  text-transform: capitalize;
`;

const Content = styled.div<{ $noPadding?: boolean }>`
  flex: 1 1 0;
  overflow: auto;

  ${(props) =>
    props.$noPadding
      ? css``
      : css`
          // 0 padding top since Header takes care of the padding
          padding: 0 16px 16px;
        `}

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.grey[800]};
  line-height: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const Item = styled.div`
  & > span:first-of-type {
    min-width: fit-content;
    padding-right: 20;
  }
  display: flex;
  flex-direction: row;
  color: #000;
  line-height: 24px;
  justify-content: space-between;
  margin: 8px 0;
`;

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;
