import { ReactElement, ReactNode, HTMLProps } from 'react';

// Components
import { Badge } from '@mui/material';

// Models
import { ColorSchema } from 'vendor/material-minimal/palette';

// Utils
import { getDOMProps } from 'app/shared/utils/react';

export interface U21BadgeProps
  extends Omit<HTMLProps<HTMLSpanElement>, 'content'> {
  children?: ReactNode | ReactElement;
  color?: 'default' | ColorSchema;
  content?: ReactNode | string | number;
  hidden?: boolean;
  variant?: 'dot' | 'standard';
}

export const U21Badge = ({
  color = 'primary',
  content,
  hidden,
  children = null,
  variant = 'standard',
  ...rest
}: U21BadgeProps) => {
  if (hidden) {
    return <>{children}</>;
  }

  if (!content && content !== 0 && variant !== 'dot') {
    return <>{children}</>;
  }

  return (
    <Badge
      badgeContent={content}
      color={color}
      variant={variant}
      {...getDOMProps(rest)}
    >
      {children}
    </Badge>
  );
};
