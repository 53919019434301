import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import {
  U21Typography,
  U21TypographyProps,
} from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { CSSProperties, HTMLProps, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { U21NoValue } from 'app/shared/u21-ui/components/display/U21NoValue';
import { getDOMProps } from 'app/shared/utils/react';
import { U21CopyButton } from 'app/shared/u21-ui/components/display/U21CopyButton';

export interface U21InfoItemProps
  extends Omit<HTMLProps<HTMLDivElement>, 'label' | 'value'> {
  enableCopy?: boolean;
  label: ReactNode;
  labelProps?: Omit<U21TypographyProps, 'as' | 'children'>;
  width?: CSSProperties['width'];
  value: ReactNode;
}

export const U21InfoItem = (props: U21InfoItemProps) => {
  const {
    enableCopy,
    label,
    labelProps,
    width = '25%',
    value,
    ...rest
  } = props;
  return (
    <U21Spacer
      align="start"
      horizontal
      spacing={0.5}
      useFlexGap
      {...getDOMProps(rest)}
    >
      <StyledU21Typography
        $width={width}
        color="text.secondary"
        ellipsis
        variant="body2"
        {...labelProps}
      >
        {label}
      </StyledU21Typography>
      {(() => {
        if (value === null) {
          return <U21NoValue />;
        }
        if (typeof value === 'string' || typeof value === 'number') {
          return <U21Typography variant="body2">{value}</U21Typography>;
        }
        return value;
      })()}
      {enableCopy &&
        (typeof value === 'string' || typeof value === 'number') && (
          <StyledU21CopyButton text={String(value)} />
        )}
    </U21Spacer>
  );
};

const StyledU21Typography = styled(U21Typography)<{
  $width: CSSProperties['width'];
}>`
  min-width: ${(props) => props.$width};
  ${(props) => {
    if (!props.$width) {
      return css``;
    }
    const width =
      typeof props.$width === 'string' ? props.$width : `${props.$width}px`;

    return css`
      min-width: ${width};
      width: ${width};
    `;
  }}
`;

const StyledU21CopyButton = styled(U21CopyButton)`
  // align vertically with text
  margin-top: -4px;
`;
