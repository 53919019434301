import { HTMLProps, ReactNode, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { ApexOptions } from 'apexcharts';
import ApexChart, { Props as ApexChartProps } from 'react-apexcharts';

// Components
import { U21NoData } from 'app/shared/u21-ui/components/display/U21NoData';

// Utils
import { getDOMProps } from 'app/shared/utils/react';
import { useTheme } from '@mui/material/styles';
import useId from '@mui/utils/useId';

export interface U21ChartProps extends HTMLProps<HTMLDivElement> {
  chartID?: string;
  height?: string | number;
  loading?: boolean;
  noDataComponent?: ReactNode;
  options?: ApexOptions;
  series: ApexChartProps['series'];
  type?: ApexChartProps['type'];
  width?: string | number;
}

const DEFAULT_OPTIONS: ApexOptions = {};

export const U21Chart = ({
  chartID: chartIDProp,
  height = 400,
  loading,
  options: optionsProp = DEFAULT_OPTIONS,
  series,
  type = 'line',
  width = '100%',
  noDataComponent = <U21NoData />,
  ...rest
}: U21ChartProps) => {
  const chartID = useId(chartIDProp);
  const theme = useTheme();
  const options = useMemo(
    () => ({
      ...optionsProp,
      chart: {
        ...optionsProp?.chart,
        fontFamily: theme.typography.fontFamily,
        id: chartID,
      },
    }),
    [chartID, optionsProp, theme],
  );

  if (!series?.length) {
    if (typeof noDataComponent === 'string') {
      return <U21NoData>{noDataComponent}</U21NoData>;
    }
    // fragment needed since typescript expects ReactElement return type
    return <>{noDataComponent}</>;
  }

  return (
    <ChartContainer $width={width} $height={height} {...getDOMProps(rest)}>
      {loading && <LoadingOverlay />}
      <StyledApexChart
        key={type} // forcing re-render when type changes
        height="100%"
        options={options}
        series={series}
        type={type}
        width="100%"
      />
    </ChartContainer>
  );
};

interface StyleProps {
  $width?: number | string;
  $height?: number | string;
}

const ChartContainer = styled.div<StyleProps>`
  position: relative;

  ${({ $height }) => {
    if (typeof $height === 'number') {
      return css`
        height: ${$height}px;
      `;
    } else if ($height) {
      return css`
        height: ${$height};
      `;
    }
    return css``;
  }}

  ${({ $width }) => {
    if (typeof $width === 'number') {
      return css`
        width: ${$width}px;
      `;
    } else if ($width) {
      return css`
        width: ${$width};
      `;
    }
    return css``;
  }}
`;

const StyledApexChart = styled(ApexChart)`
  .apexcharts-xaxis-label,
  .apexcharts-yaxis-label {
    ${(props) => props.theme.typography.caption}
  }

  .apexcharts-legend-text {
    // important needed since apexcharts injects these with style attribute
    color: ${(props) => props.theme.palette.text.primary} !important;
    font-size: ${(props) => props.theme.typography.caption.fontSize} !important;
    font-weight: ${(props) =>
      props.theme.typography.caption.fontWeight} !important;
  }

  .apexcharts-xaxis-title-text,
  .apexcharts-yaxis-title-text {
    ${(props) => props.theme.typography.subtitle2}
  }

  &&& .apexcharts-tooltip {
    background-color: ${(props) => props.theme.palette.background.default};
    border: 1px solid ${(props) => props.theme.palette.grey[500_24]};
    box-shadow: ${(props) => props.theme.customShadows.z12};
    border-radius: 4px;

    .apexcharts-tooltip-text {
      // important needed since apexcharts injects these with style attribute
      font-size: ${(props) =>
        props.theme.typography.caption.fontSize} !important;
      font-weight: ${(props) =>
        props.theme.typography.caption.fontWeight} !important;
    }

    .apexcharts-tooltip-title {
      // important needed since apexcharts injects these with style attribute
      background: ${(props) => props.theme.palette.grey[500_16]};
      border-bottom: 1px solid ${(props) => props.theme.palette.grey[500_24]};
      font-size: ${(props) =>
        props.theme.typography.subtitle2.fontSize} !important;
      font-weight: ${(props) =>
        props.theme.typography.subtitle2.fontWeight} !important;
    }
  }

  .apexcharts-tooltip-rangebar {
    font-size: ${(props) => props.theme.typography.caption.fontSize};
    font-weight: ${(props) => props.theme.typography.caption.fontWeight};

    .series-name {
      font-size: ${(props) => props.theme.typography.subtitle2.fontSize};
      font-weight: ${(props) => props.theme.typography.subtitle2.fontWeight};
    }
  }

  .apexcharts-xaxis-annotation-label {
    font-size: ${(props) => props.theme.typography.caption.fontSize};
    font-weight: ${(props) => props.theme.typography.caption.fontWeight};
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  animation: fade 2s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
  z-index: 10;

  @keyframes fade {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 0.5;
    }
  }
`;
