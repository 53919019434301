import { FC } from 'react';

// Components
import { U21Button, U21Typography } from 'app/shared/u21-ui/components';

// Utils
import { getDOMProps } from 'app/shared/utils/react';

export interface U21DocsLinkProps {
  docsLink: string;
}

export const U21DocsLink: FC<U21DocsLinkProps> = ({ docsLink, ...rest }) => {
  return (
    <U21Button
      color="info"
      variant="text"
      href={docsLink}
      {...getDOMProps(rest)}
    >
      <U21Typography color="info" variant="body2">
        Need help?
      </U21Typography>
    </U21Button>
  );
};
