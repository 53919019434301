import { JsonViewer, JsonViewerProps } from '@textea/json-viewer';
import styled, { css } from 'styled-components';

export interface U21JsonViewerProps
  extends Omit<JsonViewerProps, 'rootName' | 'value' | 'onCopy'> {
  data: object;
}

// API reference: https://viewer.textea.io/apis
export const U21JsonViewer = ({ data, ...rest }: U21JsonViewerProps) => (
  <StyledJsonViewer {...rest} rootName={false} value={data} />
);

const StyledJsonViewer = styled(JsonViewer)`
  padding: 8px;
  ${(props) => css`
    background-color: ${props.theme.palette.background.neutral};
    border-radius: ${props.theme.shape.borderRadius}px;
  `}

  ${(props) => props.theme.typography.body2};
`;
