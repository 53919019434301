import { useMemo } from 'react';

// Components
import { U21Button, U21Section } from 'app/shared/u21-ui/components';
import U21PaginationTable from 'app/shared/u21-ui/components/U21PaginationTable';

// Models
import { TableConfig } from 'app/shared/pagination/models';
import {
  U21CustomDataProps,
  CustomData,
} from 'app/shared/u21-ui/components/customData/models';

// Constants
import { CUSTOM_DATA_DOCS } from 'app/shared/constants/docs';
import { DarkWebAlertCustomData } from 'app/modules/alerts/types/responses';

const CUSTOM_DATA_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'column',
    type: 'text',
    label: 'Column',
    sortable: false,
  },
  {
    key: 'value',
    type: 'text',
    label: 'Value',
    sortable: false,
  },
  {
    key: 'description',
    type: 'text',
    label: 'Description',
    sortable: false,
  },
];

interface OwnProps extends Omit<U21CustomDataProps, 'value'> {
  // U21CustomData should provide an empty object if value is falsey
  value: CustomData | DarkWebAlertCustomData;

  toggleEditing?: () => void;
}

export const ShowCustomData = (props: OwnProps) => {
  const { value, disabled, toggleEditing, customDataKeyValuesMap } = props;

  const customDataRows = useMemo(
    () =>
      Object.keys(value).map((customDataKey) => {
        const customDataSetting = customDataKeyValuesMap?.[customDataKey];
        const customDataKeyWithLabel =
          customDataSetting?.label || customDataKey;
        const customDataSettingDescription =
          customDataSetting?.description || '';
        const providedValue = value[customDataKey];
        const formattedValue =
          typeof providedValue === 'string'
            ? providedValue
            : JSON.stringify(providedValue);

        return {
          id: customDataKey,
          column: customDataKeyWithLabel,
          value: formattedValue,
          description: customDataSettingDescription,
        };
      }),
    [value, customDataKeyValuesMap],
  );

  const editButton =
    Boolean(toggleEditing) && !disabled ? (
      <U21Button onClick={toggleEditing}>Edit</U21Button>
    ) : undefined;

  return (
    <U21Section
      title="Custom Data"
      action={editButton}
      helpModalProps={{
        children: `Custom data is a JSON object that contains values in a key:value format, check out the documentation to learn more.`,
        docsLink: CUSTOM_DATA_DOCS,
      }}
    >
      {Boolean(customDataRows.length) && (
        <U21PaginationTable
          disableFooter
          rows={customDataRows}
          config={CUSTOM_DATA_TABLE_CONFIG}
          totalCount={customDataRows.length}
          update={() => {}}
        />
      )}
    </U21Section>
  );
};
