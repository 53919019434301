import {
  U21FlattenedSelectOptionProps,
  U21SelectValue,
} from 'app/shared/u21-ui/components/input/select/U21Select';

import { ForwardedRef, forwardRef, HTMLProps } from 'react';
import { alpha } from '@mui/system/colorManipulator';
import styled from 'styled-components';

import { Menu } from 'app/shared/u21-ui/components/input/select/styles';
import { StyledU21Checkbox } from 'app/shared/u21-ui/components/input/select/SelectOptionItem';
import { U21MultiSelectProps } from 'app/shared/u21-ui/components/input/select/U21MultiSelect';
import { U21Divider } from 'app/shared/u21-ui/components/display/U21Divider';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21Tooltip } from 'app/shared/u21-ui/components/display/U21Tooltip';

export interface MultiSelectListboxProps<
  TValue extends U21SelectValue,
  TClearable extends boolean,
> extends Omit<HTMLProps<HTMLUListElement>, 'as' | 'onChange' | 'ref'> {
  allSelected?: boolean;
  selectAllTooltip?: string;
  onChange: U21MultiSelectProps<TValue, TClearable>['onChange'];
  selectAllEnabled: U21MultiSelectProps<TValue, TClearable>['selectAllEnabled'];
  selectableOptions: Omit<U21FlattenedSelectOptionProps<TValue>, 'children'>[];
  value: Exclude<U21MultiSelectProps<TValue, TClearable>['value'], undefined>;
}

const MultiSelectListboxInner = <
  TValue extends U21SelectValue,
  TClearable extends boolean,
>(
  props: MultiSelectListboxProps<TValue, TClearable>,
  ref: ForwardedRef<HTMLUListElement>,
) => {
  const {
    allSelected,
    children,
    selectAllTooltip = '',
    onChange,
    selectAllEnabled,
    selectableOptions,
    value,
    ...rest
  } = props;

  if (!selectAllEnabled) {
    return (
      <Menu ref={ref} {...rest}>
        {children}
      </Menu>
    );
  }

  return (
    <Menu ref={ref} {...rest}>
      <U21Tooltip tooltip={selectAllTooltip} placement="right">
        <SelectAllItem
          // used to apply default mui styles
          aria-selected={allSelected}
          className="MuiAutocomplete-option"
          onClick={(e) =>
            onChange(
              allSelected
                ? []
                : selectableOptions
                    .map((i) => i.value)
                    .filter((i): i is TValue => i !== undefined),
              e,
            )
          }
        >
          <U21Spacer horizontal>
            <StyledU21Checkbox
              checked={allSelected}
              indeterminate={value.length > 0}
              onChange={() => {}}
            />
            <U21Typography variant="body2">
              {allSelected ? 'Deselect all' : 'Select all'}
            </U21Typography>
          </U21Spacer>
        </SelectAllItem>
      </U21Tooltip>
      <StyledU21Divider horizontal />
      {children}
    </Menu>
  );
};

export const MultiSelectListbox = forwardRef(MultiSelectListboxInner);

const SelectAllItem = styled.li`
  border-radius: 8px;
  &&& {
    min-height: 46px;
    padding: 8px;
  }

  :hover {
    background-color: ${(props) =>
      props.theme.palette.grey[
        props.theme.palette.mode === 'light' ? 300 : 500_8
      ]};
  }

  &[aria-selected='true'] {
    :hover {
      background-color: ${(props) => {
        return alpha(
          props.theme.palette.primary.main,
          props.theme.palette.mode === 'light' ? 0.16 : 0.24,
        );
      }};
    }
  }
`;

const StyledU21Divider = styled(U21Divider)`
  margin: 8px 0;
`;
