import { FC, HTMLProps, ReactNode } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { ReactComponent as NoDataSvg } from 'app/shared/svg/no_data.svg';
import { Paper } from '@mui/material';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

export interface U21NoDataProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
}

export const U21NoData: FC<U21NoDataProps> = ({
  children = 'There is nothing to show here...',
  ...rest
}) => {
  return (
    <Container {...getDOMProps(rest)}>
      <NoDataSvg />
      <StyledTypography>{children}</StyledTypography>
    </Container>
  );
};

const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

const StyledTypography = styled(U21Typography)`
  padding: 10px;
`;
